@import url('https://fonts.googleapis.com/css?family=Poppins');
body {
    margin: 0;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: uppercase;
}

html {
    text-transform: uppercase;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.clickableSvg {
    cursor: pointer!important;
}

.clickMenu {
    font-size: 14px;
    background-color: #fff;
    border-radius: 2px;
    padding: 5px 0 5px 0;
    width: 150px;
    height: auto;
    margin: 0;
    /* use absolute positioning  */
    position: fixed;
    list-style: none;
    box-shadow: 0 0 20px 0 #ccc;
    opacity: 1;
    transition: opacity 0.5s linear;
}

.clickMenu__list {
    padding: 0.5em 1em;
    color: #000;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.clickMenu__list:hover {
    background-color: #f2f2f2;
    border-left: 4px solid #ccc;
}

.clickMenu__selected {
    background-color: #f2f2f2;
    border-left: 4px solid #ccc;
}

.clickMenu__icons {
    margin-right: 8px;
}

.dividerMenu {
    border-bottom: 1px solid #eee;
    margin: 10px 0;
}

div.acc-btn {
    /* create a grid */
    min-width: 100%;
    display: grid;
    /* create colums. 1fr means use available space */
    grid-template-columns: 1fr max-content max-content;
    align-items: center;
    grid-gap: 10px;
}

.checked-red:checked {
    background-color: #dc3545!important;
    border-color: #dc3545!important;
}

.checked-green:checked {
    background-color: #35dc35!important;
    border-color: #35dc35!important;
}

.hide-text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 15%!important;
    max-width: 15ch;
}

.headersticky {
    position: -webkit-sticky;
    position: sticky;
    /* top: 310px; */
    top: calc(var(--kt-toolbar-height) + 60px);
    padding-top: 25px;
    background-color: white;
    z-index: 3;
}

.thsticky {
    position: sticky;
    top: 330px;
    background-color: white;
    z-index: 3;
}

.table-limited-height-300 {
    max-height: 300px;
}

.scroll-card {
    max-height: 300px;
    overflow-y: auto;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 576px) {
    .mobile-capped-height {
        max-height: 15vh;
        overflow: scroll;
    }
}

.checklist_th {
    position: sticky!important;
    top: calc(var(--kt-toolbar-height) + 105px)!important;
    padding-bottom: 10px;
    background: white
}

.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: white !important;
    z-index: 4;
}

.first-col {
    width: 50px;
    min-width: 50px;
    max-width: 50px;
    left: 0px;
    padding-right: 10px;
    background-color: white !important;
    z-index: 2;
}

.second-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 50px;
    background-color: white !important;
    z-index: 2;
}

.third-col{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 200px;
    background-color: white !important;
    z-index: 2;
}

.th-estimasi-sticky {
    position: sticky;
    top: 0px;
    background-color: white !important;
    z-index: 3;
}

.th-estimasi-left-sticky {
    position: sticky;
    top: 0px;
    background-color: white !important;
    z-index: 4;
}

.disabled-pinned-content {
    padding-top: 0.25rem!important;
}

input {
    text-transform: uppercase;
}

button {
    text-transform: uppercase;
}

@media (min-width:1400px) {
    .pull-col-20px {
        // display: block;
        margin-left: -25px!important;
        // width: calc( 100% )!important;
    }
}

.btn-history-dropdown {
    height: 2rem!important;
}

.space-child:not(:last-child) {
    padding-right: 1rem;
}

.c-pointer {
    cursor: pointer;
}